<template>
  <v-card class="PlanejamentoAcaoFormCampos__Card--treslinhas">
    <v-card-title>
      <div class="card-title">
        {{ $tc('label.configuracao', 1) }}
      </div>
    </v-card-title>
    <v-card-text>
      <v-container fluid grid-list-md class="pa-0">
        <v-row dense>
          <v-col cols="12">
            <span>{{ $tc('label.modalidade') }}: {{ $tc(`configuracao.CALCULO_BONIFICACAO.${acao.calculoBonificacao}`) }}</span>
          </v-col>
          <v-col cols="12" v-if="!isCalculoBonificacaoValorFixoOrNotaFiscal">
            <span>{{ $tc('label.tipo_calculo') }}: {{ $tc(`configuracao.BONIFICACAO.${acao.formaBonificacao}`) }}</span>
          </v-col>
          <v-col cols="12">
            <span>{{ $tc('label.valor_maximo_nd') }}: {{ acao.possuiValorMaximoNd ? $tc('label.sim') + ' - ' +  $tc(`label.${acao.tipoCalculoValorMaximoND.toLowerCase()}`) : $tc('label.nao') }}</span>
          </v-col>
          <v-col cols="12">
            <span>{{ $tc('label.gatilho_performance') }}: {{ acao.possuiGatilhoGerarRecebimento ? $tc('label.sim') : $tc('label.nao') }}</span>
          </v-col>
          <v-col cols="12" v-if="!isCalculoBonificacaoValorFixoOrNotaFiscal && acao.possuiGatilhoGerarRecebimento">
            <span>{{ $tc('label.gatilho') }}: {{ $tc(`configuracao.METAS_BENEFICIOS.${acao.calculoApuracao}`) }}</span>
          </v-col>
          <v-col v-if="indHabilitaVinculoContrato || !!this.acao.idContratoVinculado" cols="12" sm="11" class="pt-12">
            <v-autocomplete
              id="planejamento-autocomplete-contrato"
              class="custom-autocomplete pt-0"
              v-model="acao.idContratoVinculado"
              :label="$tc('label.contrato', 1)"
              :items="contratos"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="descricao"
              item-value="id"
              :disabled="somenteLeitura || !habilitaVinculoContrato"
              :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
              clearable
              @click:clear="limparJustificativa"
              @click:append="() => triggerSelecao('planejamento-autocomplete-contrato')"
              @click.native="(i) => buscaAutocomplete(i, buscarContratos)">
            </v-autocomplete>
          </v-col>
          <v-col v-if="indHabilitaVinculoContrato || !!this.acao.justificativaVinculoContrato" cols="12" sm="11">
            <v-text-field
              id="justificativa"
              v-model="acao.justificativaVinculoContrato"
              :rules="[rules.required]"
              :disabled="somenteLeitura || !habilitaVinculoContrato"
              :label="`${$tc('label.justificativa', 1)} *`"/>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { buscaAutocomplete, triggerSelecao } from '../../../common/functions/autocomplete-utils';

export default {
  props: {
    somenteLeitura: Boolean,
    acao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      rules: {
        required: (value) => (!!value && !!this.acao.idContratoVinculado) || this.$t('message.campo_obrigatorio'),
      },
      contratos: [],
      indHabilitaVinculoContrato: false,
      fornecedoresVinculoCampanha: [],
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    isCalculoBonificacaoValorFixoOrNotaFiscal() {
      return this.acao.calculoBonificacao && ['VALOR_FIXO', 'NOTA_FISCAL'].includes(this.acao.calculoBonificacao);
    },
    habilitaVinculoContrato() {
      return this.getAllRoles.filter((el) => el.indexOf('VINCULAR_CAMPANHA_CONTRATO') !== -1).length > 0;
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    buscarContratos(autocomplete) {
      const parametros = {
        autocomplete,
        dataInicio: this.acao.dtaInicio,
        dataFim: this.acao.dtaFim,
      };
      this.planejamentoAcaoResource.buscarContratos(parametros)
        .then((res) => {
          this.contratos = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    validarVinculoDeContrato(idFornecedor) {
      setTimeout(() => {
        this.indHabilitaVinculoContrato = this.isCalculoBonificacaoValorFixoOrNotaFiscal
        && this.fornecedoresVinculoCampanha.includes(idFornecedor);
        if (idFornecedor && !this.indHabilitaVinculoContrato) {
          delete this.acao.idContratoVinculado;
          delete this.acao.justificativaVinculoContrato;
          this.contratos = [];
        }
      }, 1000);
    },
    alterarConfiguracaoVinculo() {
      delete this.acao.idContratoVinculado;
      delete this.acao.justificativaVinculoContrato;
      this.contratos = [];
    },
    limparJustificativa() {
      delete this.acao.idContratoVinculado;
      delete this.acao.justificativaVinculoContrato;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.acao.idContratoVinculado) {
        this.buscarContratos(this.acao.idContratoVinculado);
      }
    }, 500);
    if (this.acao.calculoBonificacao === 'VALOR_FIXO') {
      this.planejamentoAcaoResource.buscarFornecedoresVinculoCampanha()
        .then((res) => {
          this.fornecedoresVinculoCampanha = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    }
  },
};
</script>
