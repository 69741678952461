<template>
  <div>
    <v-card class="PlanejamentoAcaoFormCampos__Card--treslinhas">
      <v-card-title>
        <div class="card-title">
          {{ $tc('label.produto', 1) }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-container fluid grid-list-md class="pa-0" v-if="canEdit && !somenteLeitura">
          <v-form ref="formLinhaProduto" lazy-validation autocomplete="off">
            <v-row dense>
              <v-col cols="3">
                <v-autocomplete
                  id="verba_variavel_categoria"
                  name="verba_variavel_categoria"
                  v-model="categoriaSelecionada"
                  :filter="filtroCategoria"
                  :items="listaCategorias"
                  @input="categoriaAlterada"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  item-text="codNomeCategoria"
                  item-value="id"
                  :label="isCategoriaObrigatorio ? `${$tc('label.categoria', 1)} *` : `${$tc('label.categoria', 1)}`"
                  :placeholder="$tc('label.digite_para_buscar', 1)"
                  :clearable="!desabilitaCampoCategoria"
                  :rules="isCategoriaObrigatorio ? [rules.required] : []"
                  :required="isCategoriaObrigatorio"
                  :disabled="somenteLeitura"
                  :readonly="desabilitaCampoCategoria"
                  return-object>
                  <template slot="append" v-if="desabilitaCampoCategoria">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="orange darken-1"
                          left
                          v-on="on">
                          report_problem
                        </v-icon>
                      </template>
                      <span>{{$tc('label.selecao_categoria')}}</span>
                    </v-tooltip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  id="verba_variavel_familia"
                  name="verba_variavel_familia"
                  v-model="familiaSelecionada"
                  :filter="filtroFamilia"
                  :items="listaFamilias"
                  @click.native="buscaListaFamilias"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  item-text="codNomeFamilia"
                  item-value="id"
                  :label="isFamiliaObrigatorio ? `${$tc('label.familia', 1)} *` : `${$tc('label.familia', 1)}`"
                  :placeholder="$tc('label.digite_para_buscar', 1)"
                  clearable
                  :rules="isFamiliaObrigatorio ? [rules.required] : []"
                  :disabled="somenteLeitura || (isCategoriaObrigatorio && !categoriaSelecionada)"
                  return-object>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  id="verba_variavel_produto"
                  name="verba_variavel_produto"
                  v-model="produtosSelecionados"
                  :items="listaProdutosSelecao"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  item-text="codNomeProduto"
                  item-value="id"
                  :label="isProdutoObrigatorio ? `${$tc('label.produto', 1)} *` : `${$tc('label.produto', 1)}`"
                  multiple
                  clearable
                  chips
                  deletable-chips
                  :rules="isProdutoObrigatorio ? [rules.required] : []"
                  :required="isProdutoObrigatorio"
                  :disabled="somenteLeitura
                    || (isCategoriaObrigatorio && !categoriaSelecionada)
                    || (isFamiliaObrigatorio)"
                  return-object>
                  <template v-slot:item="{ item, attrs, on }">
                    <template v-if="item.id === 0 || item.id === -1">
                      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content v-text="item.codNomeProduto"></v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-else-if="item.id === -2">
                      <v-list-item v-on="on" v-bind="attrs" class="px-0">
                        <v-list-item-content>
                          <v-row @click.stop="abrirModalSelecaoProduto()">
                            <v-col cols="12" sm="10" align-self="center" class="botoes_selecao_produto">
                              <v-btn
                                id="btn_selecao_produto"
                                text
                                plain
                                block>
                                {{ $tc('label.selecionar_produto', 3) }}
                              </v-btn>
                            </v-col>
                            <v-col cols="12" sm="2" align-self="center" class="botoes_selecao_produto">
                              <v-btn
                                id="btn_selecao_produto"
                                icon
                                plain
                                block>
                                <v-icon
                                  class="fa-sm">
                                  arrow_forward
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-else-if="item.id === -3">
                      <v-list-item
                        v-on="on"
                        v-bind="attrs"
                        class="px-0"
                        :inactive="!categoriaSelecionada || isPossuiGatilho"
                        :disabled="!categoriaSelecionada || isPossuiGatilho"
                        @click="abrirModalUploadProduto()">
                        <v-list-item-content>
                          <v-tooltip bottom :disabled="!categoriaSelecionada || isPossuiGatilho">
                            <template v-slot:activator="{ on: tooltip }">
                              <v-list-item-title v-on="{ ...tooltip }">
                                <v-row @click.stop="abrirModalUploadProduto()">
                                  <v-col cols="12" sm="10" align-self="center" class="botoes_selecao_produto">
                                    <v-btn
                                      id="btn_upload_produto"
                                      :disabled="isPossuiGatilho"
                                      text
                                      plain
                                      block>
                                      {{ $tc('label.upload_massivo_produtos', 1) }}
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="2" align-self="center" class="botoes_selecao_produto">
                                    <v-btn
                                      id="btn_upload_produto"
                                      :disabled="isPossuiGatilho"
                                      icon
                                      plain
                                      block>
                                      <v-icon
                                        class="fa-sm">
                                        upload
                                      </v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                                <v-row @click.stop="abrirModalUploadProduto()" v-if="isPossuiGatilho">
                                  <v-col cols="12" sm="10" align-self="center" class="botoes_selecao_produto">
                                    {{ $t('label.upload_massivo_produtos_indisponivel') }}
                                  </v-col>
                                </v-row>
                              </v-list-item-title>
                            </template>
                            <span v-if="!isPossuiGatilho">{{ $t('label.upload_massivo_produtos_disponivel') }}</span>
                          </v-tooltip>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3" align-self="center" style="text-align: center">
                <v-btn id="btn_incluir_linha" @click.native="incluirLinhaProduto(null)" color="primary">
                  {{ $t('label.adicionar') }}
                </v-btn>
                <v-btn
                  v-if="linhasProduto.length > 0"
                  id="btn_remover_produtos"
                  class="ml-3"
                  color="red"
                  dark
                  @click.native="openConfirmRemocaoTodosProdutos()">
                  {{ $t('label.remover_todos') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-show="existeErroDuplicidadeLinha">
              <v-col cols="12" align-self="center" style="text-align: center">
                <v-alert type="warning"
                  elevation="2"
                  class="pa-2"
                >
                  {{ $tc('message.linha_produto_duplicada', 1) }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-form ref="formTabelaLinhasProduto" lazy-validation autocomplete="off">
          <v-row dense>
            <v-col cols="12">
              <v-data-table
                id="tabela_linha_produto"
                :headers="headersLinha"
                :items="linhasProduto"
                :key="tableLinhasKey"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                }">
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr :style="item.backgroundColor" v-for="(item, index) in items" :key="index">
                      <td class="justify-center px-0">
                        <v-tooltip bottom v-if="canEdit && !somenteLeitura">
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0" v-on="on" @click="openConfirmRemocaoLinhaProduto(item)">
                              <v-icon>delete</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('label.remover_registro') }}</span>
                        </v-tooltip>
                      </td>
                      <td >{{ item.codNomeCategoria }}</td>
                      <td >{{ item.codNomeFamilia }}</td>
                      <td v-if="item.codNomeProduto">
                        {{ item.codNomeProduto }}
                      </td>
                      <td v-else>
                        <center>
                          <v-btn icon class="mx-0" @click.stop="visualizarLinhaProduto(item, index)">
                            <v-icon>visibility</v-icon>
                          </v-btn>
                        </center>
                      </td>
                      <td class="justify-center px-0">
                        <v-tooltip bottom v-if="item.codNomeProduto || item.qtdProduto > 2">
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0" v-on="on" @click.stop="visualizarLinhaProduto(item, index)">
                              <v-icon>visibility</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $tc('label.visualizar_produto', 2) }}</span>
                        </v-tooltip>
                      </td>
                      <td v-if="verbaValorPorPeca && !acao.possuiGatilhoGerarRecebimento && acao.possuiValorMaximoNd">
                        <v-text-field
                          id="linha_produto_qtd"
                          v-model="item.quantidade"
                          type="Number"
                          min="0"
                          :rules="[rules.required]"
                          :disabled="somenteLeitura"
                          hide-spin-buttons
                          @change.native="campoQuantidadeAlterado(item)"/>
                      </td>
                      <td style="width: 10%; text-align: center;" v-if="acao.possuiGatilhoGerarRecebimento">
                        <v-btn
                          id="btn_gatilho"
                          @click="abrirMenuIntervalo(item)"
                          color="primary">
                          {{ $t('label.gatilho') }}
                        </v-btn>
                      </td>
                      <td v-else>
                        <v-text-field
                          v-if="verbaPercentual"
                          id="linha_produto_verba_percentual"
                          v-model="item.valor"
                          type="Number"
                          min="0"
                          suffix="%"
                          :rules="[rules.required, v => rules.valorMinimo(v, 0.000000001), v => rules.valorMaximo(v, 100)]"
                          :disabled="somenteLeitura"
                          hide-spin-buttons
                          @change.native="campoValorAlterado(item)"/>
                        <input-money
                          v-else
                          id="linha_produto_vlr"
                          v-model="item.valor"
                          class="v-text-field"
                          :rules="[rules.required, v => rules.valorMinimo(v, 0.000000001)]"
                          :disabled="somenteLeitura"
                          @change.native="campoValorAlterado(item)"/>
                      </td>
                      <td v-if="acao.possuiValorMaximoNd && !isCalculoNDMaximoCampanha">
                        <input-money
                        id="linha_produto_vlr_maximo_nd"
                          v-if="verbaPercentual"
                          v-model="item.valorMaximoNd"
                          :rules="[rules.required, v => rules.valorMinimo(v, 0.000000001)]"
                          :disabled="somenteLeitura"
                          @change.native="verificaCampoVlrNdAlterado(item)"
                        />
                        <span v-else class="nowrap">{{ getMoney(item.valorMaximoNd) }}</span>
                      </td>
                      <td v-if="!usuarioFornecedor && !isCalculoNDMaximoCampanha">
                       <input-money
                          id="linha_produto_recebimento_estimado"
                          v-if="!acao.possuiValorMaximoNd"
                          v-model="item.recebimentoEstimado"
                          class="v-text-field"
                          :max="9999999999"
                          :rules="[rules.required, v => rules.valorMinimo(v, 0.000000001)]"
                          :disabled="somenteLeitura || isModalidadeValorFixo"
                          @change.native="recalcularValorRecebimento()"/>
                        <span v-else class="nowrap">{{ getMoney(item.recebimentoEstimado) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:footer>
                  <v-row v-if="acao.possuiValorMaximoNd">
                    <v-col cols="12" class="mt-3" style="text-align:right; padding-right: 3%; margin-bottom:0px">
                      <span>{{ $t('label.teto_acao') }}: {{ getMoney(valorTotalAcao) }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="!usuarioFornecedor && !isCalculoNDMaximoCampanha">
                    <v-col cols="12" style="text-align:right; padding-right: 3%; margin-top:0px;">
                      <span>{{ $t('label.valor_total_estimado') }}: {{ getMoney(valorTotalRecebimento) }}</span>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <planejamento-acao-verba-variavel-modal-selecao-produto
      ref="modalFormSelecaoProduto"
      :acao="acao"
      :somente-leitura="somenteLeitura"
      :configuracao="configuracao"
      :linha-produto-selecionada="linhaProdutoSelecionada"
      :categoria-selecionada="categoriaSelecionada"
      :familia-selecionada="familiaSelecionada"
      :get-origem-acao="getOrigemAcao"
      @ModalSelecaoProduto__IncluirLinhaProduto="incluirLinhaProduto"
      @ModalSelecaoProduto__AtualizarLinhaProduto="atualizarLinhaProduto"
      @ModalSelecaoProduto__ResetaSelecao="setarValoresPadrao"
      />

    <planejamento-acao-verba-variavel-modal-meta-intervalo
      ref="modalFormMetaIntervalo"
      :acao="acao"
      :somente-leitura="somenteLeitura"
      :configuracao="configuracao"
      @ModalMetaIntervalo__RecalculaValorTotalAcao="recalcularValorTotalAcao"
      @ModalMetaIntervalo__RecalculaValorTotalRecebimento="recalcularValorRecebimento"
      />

    <planejamento-acao-verba-variavel-modal-upload-produtos
      ref="modalUploadProdutos"
      :acao="acao"
      :configuracao="configuracao"
      @ModalUploadProdutos__ConfirmarUploadProdutos="setarLinhasUpload"
      @ModalUploadProdutos__salvarMd5ArquivoProduto="salvarMd5ArquivoProduto"
      />

    <confirm
      ref="confirmRemocaoLinhaProduto"
      :message="this.isAmbienteKabum && this.linhasProduto.length === 1 && this.countListaRateio > 0 ? $t('message.deseja_remover_linha_produto_e_registros_rateio') : $t('message.deseja_remover_linha_produto')"
      :persistent="true"
      @agree="removerLinhaProduto">
    </confirm>

    <confirm
      ref="confirmRemocaoTodosProdutos"
      :message="$t('message.deseja_remover_todos_produtos')"
      :persistent="true"
      @agree="removerTodosProdutos">
    </confirm>

  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import {
  convertToMoney,
  convertToPercent,
  convertToNumberFormat,
  getMoney,
  getPercent,
  copyObject,
} from '../../../common/functions/helpers';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import InputMoney from '../../../shared-components/inputs/InputMoney';
import InputNumber from '../../../shared-components/inputs/InputNumber';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import PlanejamentoAcaoVerbaVariavelModalSelecaoProduto from './PlanejamentoAcaoVerbaVariavelModalSelecaoProduto';
import PlanejamentoAcaoVerbaVariavelModalMetaIntervalo from './PlanejamentoAcaoVerbaVariavelModalMetaIntervalo';
import PlanejamentoAcaoVerbaVariavelModalUploadProdutos from './PlanejamentoAcaoVerbaVariavelModalUploadProdutos';

export default {
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
    getOrigemAcao: Function,
    countListaRateioTipoUso: Number,
  },
  components: {
    PlanejamentoAcaoVerbaVariavelModalSelecaoProduto,
    PlanejamentoAcaoVerbaVariavelModalMetaIntervalo,
    PlanejamentoAcaoVerbaVariavelModalUploadProdutos,
    InputMoney,
    InputNumber,
    Confirm,
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      listaCategorias: [],
      listaFamilias: [],
      listaProdutosSelecao: [],
      categoriaSelecionada: null,
      familiaSelecionada: null,
      linhaProdutoSelecionada: { metas: [] },
      produtosSelecionados: [],
      linhasProduto: [],
      tableLinhasKey: 0,
      valorTotalAcao: 0,
      valorTotalRecebimento: 0,
      categoriaSomenteLeitura: false,
      countListaRateio: 0,

      modalSelecaoProduto: false,
      existeErroDuplicidadeLinha: false,

      headersLinha: [
        {
          text: '', value: '', width: '2%', sortable: false, align: 'center',
        },
        {
          text: this.$tc('label.categoria', 1), value: 'codNomeCategoria', sortable: false, width: '15%', align: 'center',
        },
        {
          text: this.$tc('label.familia', 1), value: 'codNomeFamilia', sortable: false, width: '15%', align: 'center',
        },
        {
          text: this.$tc('label.produto', 1), value: 'codNomeProduto', sortable: false, width: '25%', align: 'center',
        },
        {
          text: '', value: '', width: '2%', sortable: false, align: 'center',
        },
        {
          text: this.mudaLabelValor(), value: 'valor', sortable: false, width: '10%', align: 'center',
        },
      ],

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorMinimo: (value, min) => value >= min || this.$t('errors.valor.maior_zero_inteiro', { min }),
        valorMaximo: (value, max) => value <= max || this.$t('errors.valor.maximo', { max }),
      },
    };
  },
  watch: {
    categoriaSelecionada: {
      handler() {
        if (this.isModalidadeValorFixo) {
          this.produtosSelecionados = [{
            id: 0,
            codNomeProduto: 'Todos',
          }];
          this.familiaSelecionada = null;
        }
      },
      deep: true,
    },
    familiaSelecionada: {
      handler() {
        if (!this.familiaSelecionada && this.isModalidadeValorFixo) {
          this.produtosSelecionados = [{
            id: 0,
            codNomeProduto: 'Todos',
          }];
        }
      },
      deep: true,
    },
    produtosSelecionados() {
      if (this.produtosSelecionados.some((item) => item.id === -2)) {
        this.produtosSelecionados = this.produtosSelecionados.filter((item) => item.id !== -2);
      }
      if (this.produtosSelecionados.some((item) => item.id === -3)) {
        this.produtosSelecionados = this.produtosSelecionados.filter((item) => item.id !== -3);
      }
    },
    countListaRateioTipoUso(countListaRateio) {
      this.countListaRateio = countListaRateio;
    },
  },
  computed: {
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    desabilitaCampoCategoria() {
      return !!this.linhasProduto.length;
    },
    isCategoriaObrigatorio() {
      return this.configuracao && this.configuracao.configuracao.passo3
        && this.configuracao.configuracao.passo3.listaProduto
        && this.configuracao.configuracao.passo3.listaProduto
          .filter((campo) => ['categoria'].indexOf(campo.label) >= 0 && campo.obrigatorio === true).length > 0;
    },
    isFamiliaObrigatorio() {
      return (this.configuracao && this.configuracao.configuracao.passo3
        && this.configuracao.configuracao.passo3.listaProduto
        && this.configuracao.configuracao.passo3.listaProduto
          .filter((campo) => ['familia'].indexOf(campo.label) >= 0 && campo.obrigatorio === true).length > 0);
    },
    isProdutoObrigatorio() {
      return this.configuracao && this.configuracao.configuracao.passo3
        && this.configuracao.configuracao.passo3.listaProduto
        && this.configuracao.configuracao.passo3.listaProduto
          .filter((campo) => ['produto'].indexOf(campo.label) >= 0 && campo.obrigatorio === true).length > 0;
    },
    isModalidadeValorFixo() {
      return this.acao.calculoBonificacao
        && this.acao.calculoBonificacao === 'VALOR_FIXO';
    },
    isModalidadeSellin() {
      return this.acao.calculoBonificacao
        && this.acao.calculoBonificacao === 'SELLIN';
    },
    isModalidadeFornecedor() {
      return this.acao.calculoBonificacao
        && this.acao.calculoBonificacao === 'FATURAMENTO_FORNECEDOR';
    },
    verbaPercentual() {
      return this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'PERCENTUAL';
    },
    verbaFixo() {
      return this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'FIXO';
    },
    verbaValorPorPeca() {
      return this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'VALOR_POR_PECA';
    },
    calculoVolume() {
      return this.acao.calculoApuracao
        && (this.acao.calculoApuracao === 'SELLIN_VOLUME'
          || this.acao.calculoApuracao === 'SELLOUT_VOLUME');
    },
    calculoValor() {
      return this.acao.calculoApuracao
        && (this.acao.calculoApuracao === 'SELLIN_VALOR'
          || this.acao.calculoApuracao === 'SELLOUT_VALOR');
    },
    calculoPercentual() {
      return this.acao.calculoApuracao
        && (this.acao.calculoApuracao === 'SELLIN_PERCENTUAL'
          || this.acao.calculoApuracao === 'SELLOUT_PERCENTUAL');
    },
    calculoQuantidade() {
      return this.acao.calculoApuracao
          && (this.acao.calculoApuracao === 'QUANTIDADE');
    },
    usuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    isPossuiGatilho() {
      return this.acao.possuiGatilhoGerarRecebimento;
    },
    isCalculoNDMaximoCampanha() {
      return this.acao.tipoCalculoValorMaximoND && this.acao.tipoCalculoValorMaximoND === 'CALCULO_CAMPANHA_ND_MAXIMO';
    },
  },
  methods: {
    getMoney,
    getPercent,
    salvarMd5ArquivoProduto(md5) {
      this.$emit('PlanejamentoAcaoVerbaVariavelFormCampos__salvarMd5ArquivoProduto', md5);
    },
    buscaCategorias(autocomplete = null) {
      return this.planejamentoAcaoResource.buscarCategoria(autocomplete)
        .then((res) => {
          this.listaCategorias = res.data;
        });
    },
    categoriaAlterada() {
      if (!this.isAmbienteEpoca) {
        Promise.all([
          this.buscaListaFamilias(),
        ]).then()
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
    buscaListaFamilias(autocomplete = null) {
      if (this.isAmbienteEpoca) {
        this.buscaFamiliasEpoca(autocomplete);
      } else {
        this.buscaFamilias(autocomplete);
      }
    },
    buscaFamilias(autocomplete = null) {
      const params = {};
      if (this.categoriaSelecionada) {
        params.id_categoria = this.categoriaSelecionada.id;
      }
      if (autocomplete && autocomplete.length) {
        params.autocomplete = autocomplete;
      }

      return this.planejamentoAcaoResource.buscarFamilia(params)
        .then((res) => {
          this.listaFamilias = res.data;
          if (!this.isModalidadeValorFixo) {
            this.listaFamilias.unshift({
              id: 0,
              codNomeFamilia: 'Todos',
            });
          }
        });
    },
    buscaFamiliasEpoca(autocomplete = null) {
      const { idFornecedor, idGrupoFornecedor } = this.getOrigemAcao();

      const params = {};

      if (idFornecedor && idFornecedor >= 0) {
        params.id_fornecedor = idFornecedor;
      }
      if (idGrupoFornecedor && idGrupoFornecedor >= 0) {
        params.id_grupo_fornecedor = idGrupoFornecedor;
      }

      if (autocomplete && autocomplete.length) {
        params.autocomplete = autocomplete;
      }

      return this.planejamentoAcaoResource.buscarFamiliaEpoca(params)
        .then((res) => {
          this.listaFamilias = res.data;
          if (!this.isModalidadeValorFixo) {
            this.listaFamilias.unshift({
              id: 0,
              codNomeFamilia: 'Todos',
            });
          }
        });
    },
    mudaLabelValor() {
      if (this.acao.formaBonificacao) {
        if (this.acao.formaBonificacao === 'VALOR_POR_PECA') {
          return this.$tc('label.valor_por_peca', 1);
        }
        if (this.acao.formaBonificacao === 'PERCENTUAL') {
          return this.$tc('label.valor_percentual', 1);
        }
      }
      return this.$tc('label.valor', 1);
    },
    filtroCategoria(item, queryText, itemText) {
      if (!item) return null;
      const codCategoria = item.codNomeCategoria.toLowerCase();
      const searchText = queryText.toLowerCase();

      return codCategoria.indexOf(searchText) > -1
        || itemText.indexOf(searchText) > -1;
    },
    filtroFamilia(item, queryText, itemText) {
      if (!item) return null;
      const codFamilia = item.codNomeFamilia.toLowerCase();
      const searchText = queryText.toLowerCase();

      return codFamilia.indexOf(searchText) > -1
        || itemText.indexOf(searchText) > -1;
    },
    abrirModalSelecaoProduto() {
      const isTodosSelecionado = this.produtosSelecionados.filter((p) => p.id === 0).length > 0;
      this.$refs.modalFormSelecaoProduto.open(isTodosSelecionado);
    },
    incluirLinhaProduto(produtosSelecionadosModal = null, flagLinhaSku = false) {
      const { idFornecedor, idGrupoFornecedor } = this.getOrigemAcao();
      if (!idFornecedor && !idGrupoFornecedor) {
        this.$toast(this.$t('message.selecione_origem_acao'));
        return;
      }
      if ((!produtosSelecionadosModal || !produtosSelecionadosModal.length) && !this.$refs.formLinhaProduto.validate()) {
        return;
      }
      if (!this.isLinhaProdutoInseridaValida(produtosSelecionadosModal, null, flagLinhaSku)) {
        this.$refs.modalFormSelecaoProduto.exibeMensagemErroDuplicidade(produtosSelecionadosModal);
        return;
      }

      const linhaProduto = {
        idCategoria: null,
        codNomeCategoria: null,
        idFamilia: null,
        codNomeFamilia: null,
        codNomeProduto: null,
        quantidade: null,
        valor: 0.0,
        valorMaximoNd: 0,
        metas: [],
        qtdProduto: 0,
        listaProdutos: [],
        idsProdutos: [],
        valorMaximoNdAlterado: false,
        recebimentoEstimado: 0.0,
      };
      if (!this.novaAcao) {
        linhaProduto.idAcao = this.acao.id;
      }
      if (this.categoriaSelecionada && this.categoriaSelecionada.id !== 0) {
        linhaProduto.idCategoria = this.categoriaSelecionada.id;
        linhaProduto.codNomeCategoria = this.categoriaSelecionada.codNomeCategoria;
      }
      if (this.familiaSelecionada && this.familiaSelecionada.id !== 0) {
        linhaProduto.idFamilia = this.familiaSelecionada.id;
        linhaProduto.codNomeFamilia = this.familiaSelecionada.codNomeFamilia;
      }
      if (this.acao.possuiGatilhoGerarRecebimento) {
        if (this.calculoValor) {
          linhaProduto.metas.push({
            vlrMetaDe: 0.0,
            vlrMetaAte: 0.0,
            vlrBonificacao: 0.0,
          });
        } else {
          linhaProduto.metas.push({
            vlrMetaDe: 0.0,
            vlrMetaAte: '',
            vlrBonificacao: 0.0,
          });
        }
      }

      if (produtosSelecionadosModal && produtosSelecionadosModal.length) {
        produtosSelecionadosModal.sort((a, b) => a.codNomeProduto - b.codNomeProduto);
        if (flagLinhaSku) {
          produtosSelecionadosModal.forEach((linhaUnicaProduto) => {
            const arrayLinhaProduto = [];
            const arrayLinhaProdutoId = [];
            let linhaProdutoCopy = null;
            linhaProdutoCopy = copyObject(linhaProduto);
            arrayLinhaProduto.push(linhaUnicaProduto);
            arrayLinhaProdutoId.push(linhaUnicaProduto.id);
            linhaProdutoCopy.qtdProduto = 1;
            linhaProdutoCopy.listaProdutos = arrayLinhaProduto;
            linhaProdutoCopy.idsProdutos = arrayLinhaProdutoId;
            linhaProdutoCopy.codNomeProduto = linhaUnicaProduto.codNomeProduto;
            linhaProdutoCopy.idFornecedor = idFornecedor;
            linhaProdutoCopy.idGrupoFornecedor = idGrupoFornecedor;
            this.linhasProduto.unshift(linhaProdutoCopy);
          });
        } else {
          linhaProduto.qtdProduto = produtosSelecionadosModal.length;
          linhaProduto.listaProdutos = produtosSelecionadosModal;
          linhaProduto.idsProdutos = produtosSelecionadosModal.map((produto) => produto.id);
          if (linhaProduto.qtdProduto === 1) {
            linhaProduto.codNomeProduto = produtosSelecionadosModal[0].codNomeProduto;
          } else {
            linhaProduto.codNomeProduto = '';

            for (let i = 0; i < 2; i += 1) {
              linhaProduto.codNomeProduto = linhaProduto.codNomeProduto.concat(produtosSelecionadosModal[i].codNomeProduto);
              if (i === 0) {
                linhaProduto.codNomeProduto = linhaProduto.codNomeProduto.concat(', ');
              }
              if (i === 1 && linhaProduto.qtdProduto > 2) {
                linhaProduto.codNomeProduto = linhaProduto.codNomeProduto.concat(' ...');
              }
            }
          }
          linhaProduto.idFornecedor = idFornecedor;
          linhaProduto.idGrupoFornecedor = idGrupoFornecedor;
          this.linhasProduto.unshift(linhaProduto);
        }
      } else {
        linhaProduto.idFornecedor = idFornecedor;
        linhaProduto.idGrupoFornecedor = idGrupoFornecedor;
        this.linhasProduto.unshift(linhaProduto);
      }
      this.setarValoresPadrao();

      this.$refs.modalFormSelecaoProduto.close();
      if (this.$refs.formLinhaProduto) {
        this.$refs.formLinhaProduto.resetValidation();
      }
    },
    atualizarLinhaProduto(produtosSelecionadosModal = null, indexLinhaEmEdicao = null) {
      if (!this.isLinhaProdutoInseridaValida(produtosSelecionadosModal, indexLinhaEmEdicao)) {
        this.$refs.modalFormSelecaoProduto.exibeMensagemErroDuplicidade();
        return;
      }

      const linhaProduto = { ...this.linhaProdutoSelecionada };
      if (!this.novaAcao) {
        linhaProduto.idAcao = this.acao.id;
      }

      if (produtosSelecionadosModal && produtosSelecionadosModal.length) {
        produtosSelecionadosModal.sort((a, b) => a.codNomeProduto - b.codNomeProduto);
        linhaProduto.qtdProduto = produtosSelecionadosModal.length;
        linhaProduto.listaProdutos = produtosSelecionadosModal;
        linhaProduto.idsProdutos = produtosSelecionadosModal.map((produto) => produto.id);
        if (linhaProduto.qtdProduto === 1) {
          linhaProduto.codNomeProduto = produtosSelecionadosModal[0].codNomeProduto;
        } else {
          linhaProduto.codNomeProduto = '';
          for (let i = 0; i < 2; i += 1) {
            linhaProduto.codNomeProduto = linhaProduto.codNomeProduto.concat(produtosSelecionadosModal[i].codNomeProduto);
            if (i === 0) {
              linhaProduto.codNomeProduto = linhaProduto.codNomeProduto.concat(', ');
            }
            if (i === 1 && linhaProduto.qtdProduto > 2) {
              linhaProduto.codNomeProduto = linhaProduto.codNomeProduto.concat(' ...');
            }
          }
        }
      } else {
        linhaProduto.idsProdutos = [];
        linhaProduto.listaProdutos = [];
        linhaProduto.qtdProduto = 0;
        linhaProduto.codNomeProduto = null;
      }

      if (this.linhaProdutoSelecionada.metas && this.linhaProdutoSelecionada.metas.length) {
        linhaProduto.metas = copyObject(this.linhaProdutoSelecionada.metas);
      }
      this.linhasProduto.splice(indexLinhaEmEdicao, 1, linhaProduto);
      this.setarValoresPadrao();

      this.$refs.modalFormSelecaoProduto.close();
      if (this.$refs.formLinhaProduto) {
        this.$refs.formLinhaProduto.resetValidation();
      }
    },
    isLinhaProdutoInseridaValida(produtosSelecionadosModal, indexLinhaEmEdicao = null, flagLinhaSku = false) {
      this.limparLinhasDuplicadas(this.linhasProduto);
      for (let i = 0; i < this.linhasProduto.length; i += 1) {
        if (indexLinhaEmEdicao === null || (indexLinhaEmEdicao !== null && indexLinhaEmEdicao !== i)) {
          const linhaProduto = this.linhasProduto[i];
          if (produtosSelecionadosModal && produtosSelecionadosModal.length) {
            const idsProdutosSelecionados = produtosSelecionadosModal.map((produto) => produto.id);
            idsProdutosSelecionados.sort();
            if (linhaProduto.listaProdutos && linhaProduto.listaProdutos.length) {
              if (flagLinhaSku && linhaProduto.listaProdutos.length === 1
                && idsProdutosSelecionados.includes(linhaProduto.listaProdutos[0].id)) {
                this.listaProdutosSelecao = [{
                  id: -1,
                  codNomeProduto: 'Produtos Selecionados',
                },
                {
                  id: -2,
                  codNomeProduto: 'SelecionarProdutos',
                },
                {
                  id: -3,
                  codNomeProduto: 'UploadProdutos',
                },
                ];
                this.produtosSelecionados = [{
                  id: -1,
                  codNomeProduto: 'Produtos Selecionados',
                }];
                return false;
              }
              const idsProdutosLinhaProduto = linhaProduto.listaProdutos.map((produto) => produto.id);
              idsProdutosLinhaProduto.sort();
              if (idsProdutosLinhaProduto.toString() === idsProdutosSelecionados.toString()) {
                this.listaProdutosSelecao = [{
                  id: -1,
                  codNomeProduto: 'Produtos Selecionados',
                },
                {
                  id: -2,
                  codNomeProduto: 'SelecionarProdutos',
                },
                {
                  id: -3,
                  codNomeProduto: 'UploadProdutos',
                },
                ];
                this.produtosSelecionados = [{
                  id: -1,
                  codNomeProduto: 'Produtos Selecionados',
                }];
                return false;
              }
            }
          } else {
            if ((!linhaProduto.listaProdutos || !linhaProduto.listaProdutos.length)
              && (linhaProduto.idFamilia && this.familiaSelecionada
              && linhaProduto.idFamilia === this.familiaSelecionada.id)) {
              this.exibeErroDuplicidadeLinha(linhaProduto);
              return false;
            }
            if ((!linhaProduto.listaProdutos || !linhaProduto.listaProdutos.length)
              && ((!this.familiaSelecionada || this.familiaSelecionada.id === 0)
              && (!linhaProduto.idFamilia || linhaProduto.idFamilia === 0))
              && linhaProduto.idCategoria === this.categoriaSelecionada.id) {
              this.exibeErroDuplicidadeLinha(linhaProduto);
              return false;
            }
            if (flagLinhaSku && linhaProduto.listaProdutos.length === 1
              && linhaProduto.idFamilia && this.familiaSelecionada
              && linhaProduto.idFamilia === this.familiaSelecionada.id) {
              this.exibeErroDuplicidadeLinha(linhaProduto);
              return false;
            }
          }
        }
      }
      this.someErroDuplicidadeLinha();
      return true;
    },
    openConfirmRemocaoLinhaProduto(linhaProduto) {
      this.linhaProdutoSelecionada = linhaProduto;
      this.$refs.confirmRemocaoLinhaProduto.open();
    },
    openConfirmRemocaoTodosProdutos() {
      this.$refs.confirmRemocaoTodosProdutos.open();
    },
    removerTodosProdutos() {
      this.linhasProduto = [];
      this.recalcularValorTotalAcao();
      this.recalcularValorRecebimento();
      this.atualizarTabela();
    },
    removerLinhaProduto() {
      this.linhasProduto
        .splice(this.linhasProduto.indexOf(this.linhaProdutoSelecionada), 1);
      this.recalcularValorTotalAcao();
      this.recalcularValorRecebimento();
      if (this.isAmbienteKabum && this.linhasProduto.length === 0 && this.countListaRateio > 0) {
        this.$emit('PlanejamentoAcaoVerbaVariavelFormCampos__limparListaRateioTipoUso', true);
      }
      this.atualizarTabela();
    },
    visualizarLinhaProduto(linhaProduto, index) {
      if (linhaProduto.idCategoria) {
        this.categoriaSelecionada = {
          id: linhaProduto.idCategoria,
          codNomeCategoria: linhaProduto.codNomeCategoria,
        };
      }
      if (linhaProduto.idFamilia) {
        this.familiaSelecionada = {
          id: linhaProduto.idFamilia,
          codNomeFamilia: linhaProduto.codNomeFamilia,
        };
      }
      if (linhaProduto.listaProdutos && linhaProduto.listaProdutos.length) {
        const produtosSelecionados = [...linhaProduto.listaProdutos];
        this.$refs.modalFormSelecaoProduto.setarProdutosSelecionados(produtosSelecionados);
      }

      this.someErroDuplicidadeLinha();
      this.linhaProdutoSelecionada = linhaProduto;
      this.$refs.modalFormSelecaoProduto.setarLinhaProdutoSelecionada(linhaProduto);

      setTimeout(() => {
        if (!this.somenteLeitura && this.canEdit) {
          this.$refs.modalFormSelecaoProduto.editarLinhaProduto(index);
        } else {
          this.$refs.modalFormSelecaoProduto.visualizarLinhaProduto();
        }
      }, 1E2);
    },
    recalcularValorTotalAcao() {
      if (this.acao.possuiValorMaximoNd) {
        if (this.isCalculoNDMaximoCampanha && this.acao.tetoAcao) {
          this.valorTotalAcao = this.acao.tetoAcao;
        } else {
          const valoresMaximosNds = this.linhasProduto.map((lp) => lp.valorMaximoNd);
          this.valorTotalAcao = valoresMaximosNds.reduce((a, b) => a + b, 0);
        }
      }
    },
    recalcularValorRecebimento() {
      const recebimentoEstimado = this.linhasProduto.map((lp) => lp.recebimentoEstimado);
      this.valorTotalRecebimento = recebimentoEstimado.reduce((a, b) => a + b, 0);
      this.$emit('PlanejamentoAcaoVerbaVariavelFormCampos__atualizarValorTeToAcaoParaRateioContabil', this.valorTotalRecebimento);
      if (this.isCalculoNDMaximoCampanha) {
        this.$emit('PlanejamentoAcaoVerbaVariavelFormCampos__atualizarValorTeToAcao', this.valorTotalRecebimento);
      }
    },
    exibirMeta(valor) {
      if (!this.unidadeQuantidade) {
        if (this.calculoVolume) {
          return convertToNumberFormat(valor);
        }
        if (this.calculoPercentual) {
          return convertToPercent(valor);
        }
        if (this.calculoValor) {
          return convertToMoney(valor);
        }
      }
      return convertToNumberFormat(valor, {
        precision: 2,
      });
    },
    campoQuantidadeAlterado(item) {
      if (this.acao.possuiValorMaximoNd) {
        if (!this.acao.possuiGatilhoGerarRecebimento) {
          item.valorMaximoNd = item.valor * item.quantidade;
        } else if (this.calculoQuantidade) {
          const valorMaximo = Math.max(...item.metas.map((m) => m.vlrMaximoFaixa));
          if (valorMaximo && valorMaximo > 0) {
            item.valorMaximoNd = valorMaximo;
          } else {
            item.valorMaximoNd = 0;
          }
        }
        this.recalcularValorTotalAcao();
        if (item.quantidade === '') {
          item.recebimentoEstimado = 0;
        }
      }
    },
    verificaCampoVlrNdAlterado(item) {
      if (item.valorMaximoNd !== 0 && item.metas.length) {
        if (!item.valorMaximoNdAlterado && item.metas[0].vlrBonificacao === 0) {
          item.valorMaximoNdAlterado = true;
        }
        const { metas } = item;
        let valorMaximo = 0;
        if (this.calculoQuantidade && metas[0].vlrBonificacao !== 0) {
          valorMaximo = Math.max(...metas.map((m) => m.vlrMaximoFaixa));
        } else if (this.verbaPercentual && this.calculoValor) {
          const maiorIntervalo = metas[metas.length - 1];
          if (maiorIntervalo.vlrMetaAte) {
            valorMaximo = maiorIntervalo.vlrMetaAte * (maiorIntervalo.vlrBonificacao / 100);
          } else {
            valorMaximo = maiorIntervalo.vlrMetaDe * (maiorIntervalo.vlrBonificacao / 100);
          }
        } else {
          valorMaximo = Math.max(...metas.map((m) => m.vlrBonificacao));
        }
        if (item.valorMaximoNd === valorMaximo) {
          item.valorMaximoNdAlterado = false;
        } else {
          item.valorMaximoNdAlterado = true;
        }
      }
      if (item.valorMaximoNd) {
        item.recebimentoEstimado = item.valorMaximoNd;
      }
      this.recalcularValorTotalAcao();
      this.recalcularValorRecebimento();
    },
    campoValorAlterado(item) {
      if (this.acao.possuiValorMaximoNd) {
        if (!this.acao.possuiGatilhoGerarRecebimento && item.quantidade) {
          item.valorMaximoNd = item.valor * item.quantidade;
          item.recebimentoEstimado = item.valorMaximoNd;
        } else if (this.verbaFixa || !this.acao.formaBonificacao) {
          item.valorMaximoNd = item.valor;
          item.recebimentoEstimado = item.valorMaximoNd;
        }
        if (!this.verbaPercentual && !item.recebimentoEstimado) {
          item.recebimentoEstimado = item.valorMaximoNd;
        }
        this.recalcularValorTotalAcao();
        this.recalcularValorRecebimento();
      } else if (!this.acao.possuiValorMaximoNd && this.isModalidadeValorFixo) {
        item.recebimentoEstimado = item.valor;
      }
    },
    abrirMenuIntervalo(item) {
      this.$refs.modalFormMetaIntervalo.setaLinhaProdutoSelecionada(item);
      this.$refs.modalFormMetaIntervalo.open(item);
    },
    getObjetoFormatado() {
      if (this.isCalculoNDMaximoCampanha) {
        this.linhasProduto.forEach((item) => {
          item.valorMaximoNd = null;
        });
      }
      return this.linhasProduto;
    },
    valida() {
      let secaoProdutoValida = true;
      if (!this.linhasProduto.length) {
        secaoProdutoValida = false;
      } else if (this.acao.possuiGatilhoGerarRecebimento) {
        const qtdLinhasSemMeta = this.linhasProduto.filter((linha) => {
          if (linha.metas && linha.metas.length) {
            return linha.metas.filter((meta) => !meta.vlrBonificacao).length > 0;
          }
          return true;
        }).length;
        if (qtdLinhasSemMeta > 0) {
          secaoProdutoValida = false;
        }
      }
      return secaoProdutoValida && this.$refs.formTabelaLinhasProduto.validate();
    },
    setarValoresPadrao() {
      if (!this.isModalidadeValorFixo) {
        this.listaFamilias = [{
          id: 0,
          codNomeFamilia: 'Todos',
        }];
      }
      this.listaProdutosSelecao = [{
        id: 0,
        codNomeProduto: 'Todos',
      },
      {
        id: -2,
        codNomeProduto: 'SelecionarProdutos',
      },
      {
        id: -3,
        codNomeProduto: 'UploadProdutos',
      },
      ];
      if (!this.isModalidadeValorFixo) {
        this.familiaSelecionada = {
          id: 0,
          codNomeFamilia: 'Todos',
        };
      }
      this.produtosSelecionados = [{
        id: 0,
        codNomeProduto: 'Todos',
      }];
      if (!this.desabilitaCampoCategoria) {
        this.categoriaSelecionada = null;
      }
      this.linhaProdutoSelecionada = null;
      this.someErroDuplicidadeLinha();
      if (this.$refs.formLinhaProduto) {
        this.$refs.formLinhaProduto.resetValidation();
      }
    },
    exibeErroDuplicidadeLinha(linhaProduto) {
      this.existeErroDuplicidadeLinha = true;
      linhaProduto.backgroundColor = 'background-color:#ef5350;';
    },
    someErroDuplicidadeLinha() {
      this.existeErroDuplicidadeLinha = false;
      this.limparLinhasDuplicadas(this.linhasProduto);
    },
    setarHeader() {
      if (this.verbaValorPorPeca && !this.acao.possuiGatilhoGerarRecebimento) {
        this.headersLinha = [
          {
            text: '', value: '', sortable: false,
          },
          {
            text: this.$tc('label.categoria', 1), value: 'codNomeCategoria', sortable: false, width: '16%', align: 'center',
          },
          {
            text: this.$tc('label.familia', 1), value: 'codNomeFamilia', sortable: false, width: '16%', align: 'center',
          },
          {
            text: this.$tc('label.produto', 1), value: 'codNomeProduto', width: '25%', sortable: false, align: 'center',
          },
          {
            text: '', value: '', sortable: false,
          },
        ];

        if (this.acao.possuiValorMaximoNd) {
          this.headersLinha.push(
            {
              text: this.$tc('label.quantidade_esperada', 1), value: 'quantidade', width: '10%', sortable: false, align: 'center',
            },
          );
        }

        this.headersLinha.push(
          {
            text: this.$tc('label.valor_por_peca', 1), value: 'valor', width: '10%', sortable: false, align: 'center',
          },
        );
      }
      if (this.acao.possuiValorMaximoNd && !this.isCalculoNDMaximoCampanha) {
        this.headersLinha.push(
          {
            text: this.$tc('label.teto_agrupamento', 1), value: 'valorMaximoNd', width: '10%', sortable: false, align: 'center',
          },
        );
      }
      if (!this.usuarioFornecedor && !this.isCalculoNDMaximoCampanha) {
        this.headersLinha.push(
          {
            text: this.$tc('label.valor_estimado_nd', 1), value: 'recebimentoEstimado', width: '10%', sortable: false, align: 'center',
          },
        );
      }
      this.atualizarTabela();
    },
    atualizarTabela() {
      this.tableLinhasKey += 1;
    },
    limparLinhasDuplicadas(linhasProduto) {
      linhasProduto.forEach((item) => {
        if (item && item.backgroundColor) {
          item.backgroundColor = '';
        }
      });
    },
    apagarTodosProdutos() {
      this.linhasProduto = [];
      this.valorTotalAcao = 0;
      this.valorTotalRecebimento = 0;
      this.setarValoresPadrao();
    },
    abrirModalUploadProduto() {
      if (!this.categoriaSelecionada || this.isPossuiGatilho) {
        this.produtosSelecionados = this.produtosSelecionados.filter((item) => item.id !== -3);
      } else {
        const { idFornecedor, idGrupoFornecedor } = this.getOrigemAcao();
        const params = {
          idCategoria: this.categoriaSelecionada.id,
          idFornecedor,
          idGrupoFornecedor,
        };
        if (this.familiaSelecionada && this.familiaSelecionada.id) {
          params.idFamilia = this.familiaSelecionada.id;
        }
        this.$refs.modalUploadProdutos.open(params);
      }
    },
    setarLinhasUpload(linhas) {
      const linhasAgrupadas = this.agruparLinhas(linhas);
      const { idFornecedor, idGrupoFornecedor } = this.getOrigemAcao();
      linhasAgrupadas.forEach((linha) => {
        linha.idAcao = this.acao.id;
        linha.qtdProduto = 1;
        linha.codNomeCategoria = this.categoriaSelecionada.codNomeCategoria;
        linha.codNomeProduto = this.formatarNomeProdutos(linha.listaProdutos);
        linha.valorMaximoNdAlterado = false;
        linha.metas = [];
        if (idFornecedor) {
          linha.idFornecedor = idFornecedor;
        }
        if (idGrupoFornecedor) {
          linha.idGrupoFornecedor = idGrupoFornecedor;
        }
        if (this.familiaSelecionada && this.familiaSelecionada.id) {
          linha.codNomeFamilia = this.familiaSelecionada.codNomeFamilia;
        }
        this.ajustarCamposValorImportacao(linha);
      });
      this.linhasProduto = [...linhasAgrupadas];
      setTimeout(() => {
        this.recalcularValorTotalAcao();
        this.recalcularValorRecebimento();
        this.atualizarTabela();
      }, 500);
    },
    ajustarCamposValorImportacao(item) {
      if (this.acao.possuiValorMaximoNd) {
        let alterarRecebimentoEstimado = false;
        if (!this.acao.possuiGatilhoGerarRecebimento && item.quantidade) {
          item.valorMaximoNd = item.valor * item.quantidade;
          alterarRecebimentoEstimado = true;
        } else if (this.verbaFixa || !this.acao.formaBonificacao) {
          item.valorMaximoNd = item.valor;
          alterarRecebimentoEstimado = true;
        }
        if (!this.verbaPercentual && !item.recebimentoEstimado) {
          alterarRecebimentoEstimado = true;
        }
        if (alterarRecebimentoEstimado) {
          item.recebimentoEstimado = item.valorMaximoNd;
        }
      }
    },
    agruparLinhas(linhas) {
      const linhasSemAgrupamento = linhas.filter((el) => !el.agrupamento);
      const linhasComAgrupamento = linhas.filter((el) => el.agrupamento);
      const agrupamentos = Object.values(this.agruparLinhasAgrupamento(linhasComAgrupamento));
      const linhasAgupadas = [];
      agrupamentos.forEach((agrupamento) => {
        const auxIdProduto = [];
        const auxListaProduto = [];
        let quantidade = 0;
        agrupamento.forEach((element, index) => {
          const [idProduto] = element.idsProdutos;
          const [idListaProdutos] = element.listaProdutos;
          auxIdProduto.push(idProduto);
          auxListaProduto.push(idListaProdutos);
          if (element && element.quantidade) {
            quantidade += element.quantidade;
          }
          if (agrupamento.length - 1 === index) {
            linhasAgupadas.push({
              ...element,
              quantidade: quantidade !== 0 ? quantidade : null,
              idsProdutos: auxIdProduto,
              listaProdutos: auxListaProduto,
            });
          }
        });
      });
      linhasAgupadas.sort((a, b) => a.agrupamento > b.agrupamento);
      if (linhasSemAgrupamento && linhasSemAgrupamento.length) {
        linhasSemAgrupamento.forEach((el) => linhasAgupadas.push(el));
      }
      return linhasAgupadas;
    },
    agruparLinhasAgrupamento(linhas) {
      const agrupamentos = linhas.reduce((acumulador, linha) => {
        const chave = linha.agrupamento;
        if (!acumulador[chave]) {
          acumulador[chave] = [];
        }
        acumulador[chave].push(linha);
        return acumulador;
      }, {});
      return agrupamentos;
    },
    formatarNomeProdutos(listaProdutos) {
      let stringProduto = '';
      if (listaProdutos && listaProdutos.length) {
        listaProdutos.forEach((produto, index) => {
          if (index === 0) {
            stringProduto = produto.idExterno.concat(' - ').concat(produto.nomProduto);
          } else if (listaProdutos.length > 2 && index === 2) {
            stringProduto = `${stringProduto} ...`;
            return stringProduto;
          } else {
            stringProduto += `, ${produto.idExterno.concat(' - ').concat(produto.nomProduto)}`;
          }
          return stringProduto;
        });
      }
      return stringProduto;
    },
  },
  mounted() {
    this.buscaCategorias();
    this.setarValoresPadrao();
    this.setarHeader();
    if (!this.novaAcao) {
      this.linhasProduto.push(...this.acao.linhasProduto);
      for (let i = 0; i < this.linhasProduto.length; i += 1) {
        const element = this.linhasProduto[i];
        if (element.qtdProduto > 2) {
          element.codNomeProduto = element.codNomeProduto.concat(' ...');
        }
        if (i === 0) {
          this.categoriaSelecionada = {
            id: element.idCategoria,
            codNomeCategoria: element.codNomeCategoria,
          };
        }
      }
      if (this.acao.possuiValorMaximoNd) {
        this.recalcularValorTotalAcao();
      }
      if (!this.usuarioFornecedor) {
        this.recalcularValorRecebimento();
      }
    }
  },
};
</script>
<style>
.botoes_selecao_produto .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover),
.botoes_selecao_produto .v-btn__content {
  opacity: 1 !important;
}
#tabela_linha_produto th {
  text-align: center !important;
}
</style>
